import React from "react"
import RegionReport from "../components/Report/regionReport"
import Layout from "../components/Base/Layout"

export default function categoryReportTemplate({ pageContext }) {
  return (
    <Layout>
      <RegionReport slug={pageContext.slug} />
    </Layout>
  )
}
